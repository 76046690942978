/* Sidebar.scss */
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdU > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: red !important;
}
.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
}

.nav-item {
  margin-bottom: 10px;
}

.nav-link {
  color: black;
}

.nav-link.active {
  color: #f5821f;
} 
.c-menu__item{
  color: #f5821f;
}
.menuClass{
  color: #f5821f;
}

/* Submenu.css */
 .submenu-text {
/* Apply default styles for the collapsed submenu */
display: block;
color: #9f9999;
 } 
.submenu-text active{
  /* Apply default styles for the collapsed submenu */
  color: #f5821f;
  }
.c-menu-item__title{
color: #9f9999;
}

.c-menu > ul .c-menu__item .collapse-menu[aria-expanded="true"]:after {
transform: rotate(-90deg);
}

.profileImage{
/* width: 121px;
height: 100px; */
}
.submenu-text .active{
color: #f5821f !important;
}
.menu-text.active{
color: #f5821f;
}
.l-sidebar {
width: 184px;
z-index: 10;
background: #fff;
transition: width .5s ease-in-out;
box-shadow: 5px 0 5px -2px #e1e1e1;
}

.header-icons-group .dropdown span {
font-size: 17px !important;
}

.pmo-report table {
border-collapse: collapse;
width: 81%;
margin-left: 194px;
}
.hamburger-toggle {
position: relative;
padding: 0;
background: transparent;
border: 1px solid transparent;
cursor: pointer;
order: 1;
}
.hamburger-toggle [class*='bar-'] {
display: block;
background: #a5a5a5;
-webkit-transform: rotate(0deg);
transform: rotate(0deg);
-webkit-transition: .2s ease all;
transition: .2s ease all;
border-radius: 2px;
height: 2px;
width: 24px;
margin-bottom: 4px;
}
.hamburger-toggle [class*='bar-']:nth-child(2) {
width: 18px;
}
.hamburger-toggle [class*='bar-']:last-child {
margin-bottom: 0;
width: 12px;
}
.hamburger-toggle.is-opened {
left: 3px;
}
.hamburger-toggle.is-opened [class*='bar-'] {
background: #a5a5a5;
}
.hamburger-toggle.is-opened .bar-top {
-webkit-transform: rotate(45deg);
transform: rotate(45deg);
-webkit-transform-origin: 15% 15%;
transform-origin: 15% 15%;
}
.hamburger-toggle.is-opened .bar-mid {
opacity: 0;
}
.hamburger-toggle.is-opened .bar-bot {
-webkit-transform: rotate(45deg);
transform: rotate(-45deg);
-webkit-transform-origin: 15% 95%;
transform-origin: 15% 95%;
width: 24px;
}
.hamburger-toggle:focus {
outline-width: 0;
}
.hamburger-toggle:hover [class*='bar-'] {
background: #f5821f;
}
.l-sidebar {
}

.page-content{
margin-left: 196px;
height: 100%;
position: relative;
}
.page-content.active {
margin-left: 70px;
}

.sidenav---sidenav---_2tBP {
height: 100vh !important;
position: fixed !important;
}
/**/
.top-data {
display: flex;
justify-content: start;
align-items: start;
}
.sidenav---sidenav-toggle---1KRjR {
top: -8em;
right: -9em;
}
.logo {
position: relative;
left: 30px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
color: #000;
font-size: 15px;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > * {
  color: #838383 !important;
}
nav.sidenav---sidenav---_2tBP {
background: #fff;
transition: width 0.5s ease-in-out;
box-shadow: 5px 0 5px -2px #e1e1e1;
}

.sidenav---navitem---9uL5T {
font-size: 15px !important;
color: #222222 !important;
white-space: nowrap !important;
overflow: hidden;
text-overflow: ellipsis;
transition: all 0.5s ease-in-out;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
width: 24px;
}
.sidenav---sidenav-toggle---1KRjR .sidenav---icon-bar---u1f02 {
background-color: #a5a5a5 !important;
transition: .2s ease all !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
color: #000 !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > *, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdU > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
color: #f5821f !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-:hover > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo, .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
color: #000 !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f > * {
color: #222222 !important;
}
.sidenav---expanded---1KdUL img.profileImage {
width: 92px;
height: 92px;
margin: 0 auto 10px;
display: block;
margin-top: -15px;
}
img.profileImage {
width: 50px;
height: 50px;
border-radius: 50%;
object-fit: cover;
object-position: center right;
transition: all .5s;
}
.profile-pic {
border-top: transparent;
margin-top: 0;
}
.sidenav---expanded---1KdUL .l-logo,
.sidenav---collapsed---LQDEv .r-logo {
  display: block;
}

.sidenav---collapsed---LQDEv .l-logo,
.sidenav---expanded---1KdUL .r-logo {
  display: none;
}
.sidenav---collapsed---LQDEv .sidenav---sidenav-toggle---1KRjR {
  top: -5.5em;
  right: -3em;
}

.sidenav---collapsed---LQDEv .logo {
  top: 45px;
  left: 20px;
}

.sidenav---collapsed---LQDEv .sidenav---sidenav-toggle---1KRjR {
  top: -5em;
  right: -0.2em;
}
.sidenav---collapsed---LQDEv .profile-pic span {
  max-width: 45px;
  text-align: center;
  margin: 0 auto;
  margin-top: 10px;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---expandable---3_dr7 .sidenav---sidenav-subnavitem---1cD47:first-child,
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnavitem---1cD47:first-child {
  display: none !important;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61,
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 {
  left: -6px;
  min-width: auto;
  background: transparent;
  border: none;
  box-shadow: none;
  top: 40px;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-:hover.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 .sidenav---navtext---1AE_f,
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 .sidenav---navtext---1AE_f {
  display: none !important;
}

.sidenav---expanded---1KdUL .badge-collapse, .profile-pic span{
  display: none !important;
}

.sidenav---expanded---1KdUL .profile-pic span{
  display: block !important;
}

.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo .badge-collapse{
  color : #fff !important;
  left: 37px;
}

.sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo .fa::before {
  width: 20px;
  height: 20px;
  display: inline-block;
}
.submenu-text.active{
  color: #f5821f !important;
}
.submenuIcon.active{
  display: none;
}
.questions-list {
  text-align: left;
  color: #4e4e4e;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

.btn-list {
  text-align: left;
}
.faq-page {
  padding-right: 0px;
}
.page-content.active .fourty-fluid.fourty-fluid {
  max-width: 1220px;
  margin: 0 auto;
}
.list-qst {
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #D7D7D7;
}
.scrollable-content ul {
  padding-left: 0;
}
.btn-generate {
  margin-top: auto;
}
.title-bar {
  position: absolute;
  top: 10px;
  left: 60px;
}

.title-bar h3 {
  font-size: 20px;
}

.title-bar button {
  text-align: right;
  margin-left: auto;
}

.sideClass{
  background: #fff;
}
.sidenav---expanded---1KdUL .sideClass {
  margin-top: -60px !important;
}
.sidenav---collapsed---LQDEv .sideClass {
  margin-top: -15px !important;
}
.add-qst-btn {
  text-align: right;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv div.sideClass.sidenav---sidenav-nav---3tvij .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7>.sidenav---sidenav-subnav---1EN61 {
  display: block !important;
}
.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv .sidenav---sidenav-nav---3tvij .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7:hover>.sidenav---sidenav-subnav---1EN61 {
  display: none !important;
}
.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y .sidenav---navicon---3gCRo i {
  color: #f5821f;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  display: flex;
  padding: 4px 14px 0 20px !important;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  /* padding-left: 10px; */
  padding-top: 5px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navicon---3gCRo + .sidenav---navtext---1AE_f {
  padding-top: 0px;
}
.sidenav---navtext---1AE_f a.nav-link {
  /* padding-top: 15px; */
  padding-left: 0;
}

.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navicon---3gCRo {
  padding-top: 3px;
}

.l-header__inner{
  top:0;
}
/**/
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
  display: flex;
  align-items: center;
  vertical-align: middle;
  height: auto;
    line-height: inherit;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  width: 47px !important;
  margin-right: 0 !important;
  height: 20px !important;
}
.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T {
  height: 38px !important;
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL .sidenav---navitem---9uL5T {
  position: relative !important;
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navitem---9uL5T:after {
  display: inline-block !important;
  font-family: FontAwesome !important;
    font-weight: normal !important;
    font-size: 20px !important;
    text-rendering: auto !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    content: "\f107" !important;
    transition: all linear 0.25s !important;
    float: right !important;
    position: absolute !important;
    top: 0px !important;
    right: 15px !important;
    background: transparent !important;
    color: #000 !important;
    opacity: 1 !important;
    left: inherit !important;
    z-index: 1 !important;
    width: auto !important;
    /* transform: rotate(90deg); */
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL  .sidenav---sidenav-subnav---1EN61 .sidenav---navitem---9uL5T:after, .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 .sidenav---navitem---9uL5T:after {
content: "" !important;
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL .sidenav---navitem---9uL5T:after, .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navitem---9uL5T:after {
  /* transform: rotate(-90deg); */
  content: "\f106" !important;
    top: 0px !important;
    right: 15px !important;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61:before, .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61:before {
  border-color: rgba(0, 0, 0, 0) !important;
  border-bottom-color: #c3b8b8 !important;
  border-width: 8px !important;
  margin-left: -8px;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61:after, .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61:after {
  border-color: rgba(255, 255, 255, 0) !important;
    border-bottom-color: #ffffff !important;
    border-width: 7px !important;
    margin-left: -7px;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 {
  left: 0;
    right: 0;
    padding-top: 5px;
    position: relative;
    border: 1px solid #dad4d4;
    background: #ffffff;
    top: 0;
    width: auto;
    margin-top: 5px;
  overflow: inherit;
}
.active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 {
  left: 0 !important;
    right: 0;
    padding-top: 5px;
    position: relative;
    border: 1px solid #dad4d4 !important;
    background: #ffffff !important;
    top: 0 !important;
    width: auto;
    margin-top: 5px;
  overflow: inherit;
}
.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61:before, .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61:after {
  bottom: 100%;
    left: 30px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
 }
 .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61:after, .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61:before{
  bottom: 100%;
    left: 35px;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
 }
 .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47:nth-child(2) {
  margin-top: 0px !important;
 }
 .sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  margin-right: 10px !important;
 }
 .sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f a .submenu-text, .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f a .submenu-text {
  font-size: 14px;
  line-height: 1.7;
  color: #666666;
  padding-top: 3px;
}
.sidenav---collapsed---LQDEv .sideClass.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo {
  width: 100% !important;
}
.sidenav---collapsed---LQDEv .sideClass.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navitem---9uL5T:after {
  right: 7px !important;
}
.sidenav---collapsed---LQDEv .sideClass.sidenav---sidenav-nav---3tvij > .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---navitem---9uL5T:after {
  right: 10px !important;
}
.sidenav---collapsed---LQDEv .sideClass.sidenav---sidenav-nav---3tvij > .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T {
  /* padding-left: 15px; */
  line-height: inherit;
}
.sidenav---collapsed---LQDEv .sideClass.sidenav---sidenav-nav---3tvij > .active.sidenav---sidenav-navitem---uwIJ-.sidenav---expandable---3_dr7 .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47 > .sidenav---navitem---9uL5T .submenu-text {
  padding-bottom: 10px;
}
.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y, .sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u {
  position: relative;
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---selected---1EK3y.sidenav---highlighted---oUx9u:after {
  content: "";
  position: absolute;
  border-right: 2px solid #f5821f;
  color: #f5821f;
  height: 30px;
  right: 0;
  top: 10px;
}
.sidenav---sidenav-subnavitem---1cD47.sidenav---selected---1EK3y {
  border-right: 2px solid #f5821f;
  color: #f5821f;
}
.sidenav---sidenav-navitem---uwIJ-.sidenav---highlighted---oUx9u.sidenav---expandable---3_dr7.sidenav---expanded---1KdUL .sidenav---navitem---9uL5T .sidenav---navtext---1AE_f {
  color: #f5821f !important;
}

.sidenav---sidenav-nav---3tvij > .sidenav---sidenav-navitem---uwIJ- > .sidenav---navitem---9uL5T .sidenav---navicon---3gCRo > .active{
  color: #f5821f !important;
}
/* -----------------------------------------
   Notification
----------------------------------------- */
.timeline {
  list-style: none;
  padding-left: 0;
  position: relative;
  word-wrap: break-word;
  overflow: auto;
  max-height: 48vh;
  margin-top: 10px 2px;
}

.timeline.timeline-sm:after {
  left: 12px;
}
.timeline li {
  position: relative;
  padding-left: 2px;
  margin-bottom: 5px;
}

.notifications-item{
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 6px 9px;
  margin-bottom: 0px;
  cursor: pointer;
}

.notifications-item p.text-wrap{
  display: -webkit-box;
  max-width: 200px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.notifications-item:hover, .notifications-item.unread-item:hover{
  background-color: rgb(212, 212, 212);
}

.notifications-item.unread-item{
  background-color: #eee;
}

.notifications-item span{
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 9px;
  border-radius: 50%;
  border: 1px solid #F5821F;
  background-color: #F5821F;
  margin-top: 8px;
  text-align: center;
  line-height: 29px;
  color: #F5D7BC;
}

.notifications-item i{
  color: #FFF;
  top: 6px;
  font-size: 28px;
  position: relative;
  display: inline-block;
}

.notifications-item .text h5{
  color: #F5821F;
  font-size: 14px;
  line-height: 14px;
  margin-top: 3px;
  margin-bottom: 4px;
}
.notifications-item .text p{
  color: #8a8888;
  font-size: 12px
}

/* -----------------------------------------
   Dropdown
----------------------------------------- */
.notification-dropdown .dropdown-menu{
    padding:0 0 0 0;
    width: 18rem;
    left: -130px;
    top: 49px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}

.notification-dropdown .dropdown-menu::after{
  content:'';
  position: absolute;
  bottom: 99%;
  left: -10px;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-bottom: solid 12px #fff;
  border-left: solid 12px transparent;
  border-right: solid 12px transparent;
}

.notification-dropdown .dropdown-menu > li h6 {
  padding: 7px 15px;
  border-bottom: 1px solid #ddd;
}

.notification-dropdown .dropdown-menu > li h6 button {
  background-color: #fff;
  border: 1px solid #F5821F;
  color: #F5821F;
}

.notification-dropdown .dropdown-menu > li h6 span {
  font-size: 10px !important;
}
/* -----------------------------------------
   Badge
----------------------------------------- */

.notification-dropdown .badge{
    padding: 3px 5px 3px;
    position: relative;
    bottom: 8px;
    right: 8px;
    display: inline-block;
    min-width: 10px;
    font-size: 8px;
    font-weight: bold;
    font-style: normal;
    color: #ffffff;
    line-height: 1;
    vertical-align: baseline;
    white-space: nowrap;
    text-align: center;
    border-radius: 10px;
}
.notification-dropdown .badge-danger {
    background-color: #db5565;
}

.badge-warning{
  background: #ffb400;
  color: #ffffff !important;
  position: absolute;
  border-radius: 12px;
  top: 8px;
  left: auto;
  font-style: normal;
  font-weight: 100;
  font-size: 10px !important;
  padding: 3px 7px 2px 7px;
}
