.css-1n7v3ny-option {
    color: #575757 !important;
    background-color: transparent !important;
}
.css-1n7v3ny-option:hover {
    background-color: #f1f1f1 !important;
}
.css-yt9ioa-option:hover {
    background-color: #f1f1f1 !important;
}
.table-row{
   border-bottom: 1px solid #dfdfdf;
}
.table-cell{
    padding: 15px !important;
}

.text-orange{
    color: #F5821F;
}
.btn-pointer{
    cursor: pointer;
}
.border-bottom{
    border-bottom: 2px solid #e9e9e9 !important;
}
.font-weight-bold{
    font-weight: bolder;
    font-family: 'Conv_bellgothicstd-black';
}
.chapter-review-page textarea.form-control{
    height: 85px;
}
.chapter-review-page b.form-control{
    height: 15px;
}
.chapter-review-page .form-group b, .chapter-review-page .form-group  {
    color: #000;
}
.pr-l-gray-transparent{
    border: 1px solid #787878;
    border-radius: 30px;
    color: #787878;
}

.project-list ul li {
    font-size: 11px;
}

.chapter-tabs .nav-tabs .nav-link.active, .chapter-tabs .nav-tabs .nav-link:hover{
    background: transparent;
    border-color: transparent !important;
    border-bottom: 1px solid #f5821f !important;
    color: orange;
}

.chapter-tabs .nav-tabs .nav-item {
    margin-bottom: 1px !important;
}

.chapter-modal{
    margin-left: 2px !important;
}

.chapter-progress label span{
    position: relative;
    left: 470px;
}

.chapter-filter .level-filter{
    padding: 10px;
}


.chapter-progress .progress{
    position: relative;
    height: 0.7rem;
    border-radius: 2.25rem;
}

.chapter-progress .progress .bg-warning{
    background-color: #f07f1e !important;
}