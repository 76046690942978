.form-container {
  max-width: 820px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #fff;
}
 
.default-bg {
  background: #4E4E4E;
  color: #fff;
}
 
.custom-form {
  display: grid;
}
 
.custom-form input[type="radio"] {
  width: auto;
}
 
.form-group {
  margin-bottom: 15px;
}
 
label {
  font-weight: bold;
}
 
.service-container {
  padding: 12px 0px;
}
 
.style-label {
  color: #B8AEAE;
  padding-bottom: 10px;
}
 
.style-service {
  color: #333333;
}
 
.c-black {
  color: #000;
}
 
.pr-l-gray {
  background: #787878;
  border-radius: 30px;
  color: #fff;
}
 
.pr-l-gray:hover,
.pr-l-gray:active {
  color: #fff;
}
 
.b-r-3 {
  border-radius: 3px;
}
 
.h100v {
  height: 100vh;
}
 
.underline {
  text-decoration: underline;
  cursor: pointer;
}
 
.style-deadline {
  color: #999999
}
 
.success-color {
  color: #0FA958;
}
 
.warning-color {
  color: #F5821F;
}
 
.danger-color {
  color: red
}
 
.default-color {
  color: #BDBDBD
}
 
 
.success-border {
  border: 1px solid #0FA958;
}
 
.warning-border {
  border: 1px solid #F5821F;
}
 
.danger-border {
  border: 1px solid red
}
 
.default-border {
  border: 1px solid #BDBDBD;
}
 
.style-percentage-overall {
  text-align: center;
  align-items: center;
  display: flex;
  font-size: 16px !important;
  padding: 7px;
}
.style-percentage-overall.style-percentage-danger.count {
  position: relative;
  /* right: 13px; */
} 
.style-percentage-overall.style-percentage-danger.admin {
  position:relative;
  left: 70px;
}
.style-percentage-success {
  color: #0FA958;
  border: 1px solid #0FA958;
}
 
.style-percentage-warning {
  color: #F5821F;
  border: 1px solid #F5821F;
}
 
.style-percentage-danger {
  color: red;
  border: 1px solid red;
}
 
.style-percentage-default {
  color: #BDBDBD;
  border: 1px solid #BDBDBD;
}
 
.style-percentage.initial {
  margin: 10px;
  margin-left: 0;
  padding: 3px 9px;
}
 
.style-percentage {
  padding: 7px;
}
 
.style-percentage span {
  font-size: 10px;
}
 
.chapter-percent-details {
  display: flex;
  flex-direction: column;
  width: 75%;
}
 
.chapter-percent-details p {
  word-break: break-all;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
 
.chapter-percent-details p:hover {
  overflow: visible;
  white-space: normal;
  height: auto;
}
 
input,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}
 
.submit-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 3px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
 
.submit-button:hover {
  background-color: #0056b3;
}
 
/* .user-grid-table {
  min-height: 30vh;
  max-height: 90vh;
} */
 
.user-grid-table thead {
  position: sticky;
  z-index: 9;
}
 
.user-grid-table td {
  color: #7D7D7D
}
 
.user-grid-table.table>:not(:first-child) {
  border-top: 0px;
}
 
.name-cell {
  align-items: center;
}
 
.comment-section {
  border-bottom: 2px solid #D7D7D7;
  padding-bottom: 10px;
  position: relative;
}
 
.comments-iput-wrapper {
  position: relative;
}
 
.comment-section .disabledInput,
.comments-iput-wrapper textarea:disabled {
  background: #f1f3f4;
  border: 1px solid #00000033;
  color: #00000080;
  padding: 10px;
  padding-bottom: 25px;
  border-radius: 3px;
}
 
.comments-iput-wrapper textarea,
.comments-iput-wrapper textarea:focus {
  border: 1px solid #84848480 !important;
  border-color: #84848480 !important;
  border-radius: 3px;
  padding: 10px 10px;
  width: 100%;
}
 
.comments-iput-wrapper i {
  color: #d1d1d1;
  position: absolute;
  top: 13px;
  left: 8px;
  font-size: 20px;
}
 
textarea::placeholder {
  padding-top: 6px;
  color: #00000080;
  font-size: 12px;
  font-weight: 300;
}
 
.star-wrapper .star-container .hovered {
  background-color: #fff;
}
 
.star-wrapper {
  align-items: baseline;
}
 
.page-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 10px;
  text-align: end;
  z-index: 99;
  background: #363636;
}
 
.page-footer button {
  text-transform: capitalize !important;
}
 
.comment-error {
  position: absolute;
  right: 32px;
  top: 0;
  font-size: 12px;
  color: red;
}
 
.pms-dashboard-container button:focus:not(:focus-visible),
button:focus:not(:focus-visible) {
  outline: 0 !important;
  box-shadow: none !important;
}
 
.main-accordion-title {
  background-color: #f1f1f1;
}
 
.sub-accordion-title {
  background-color: #eef5f5;
}
 
.rotate-icon {
  transform: rotate(180deg);
}
 
.level-filter {
  padding: 0px 10px;
  border-bottom: 1px solid #bababa;
  cursor: pointer;
}
 
.level-filter.active {
  border-bottom: 1px solid #F5821F;
  color: #F5821F;
}
 
.w-25 {
  min-width: 25%;
}
 
.w-13 {
  min-width: 13%;
}
 
.w100 {
  width: 100%;
}
 
.lh-10 {
  line-height: 10px;
}
.addquestion{
  line-height: 3.5;
} 
.c-not-allowed {
  cursor: not-allowed;
}
 
.selectpicker_dropdown.bu-name {
  width: fit-content;
  margin: 3px 0px;
}
 
.performance-year .select_dropdown__control {
  border-bottom: none !important;
}

.performance-year-dashboard .select_dropdown__control{
  border-bottom: inset !important; 
}
 
.performance-year .select_dropdown__control .select_dropdown__placeholder,
.performance-year .select_dropdown__control .select_dropdown__indicators svg,
.performance-year .select_dropdown__control .select_dropdown__input-container,
.performance-year .select_dropdown__control .select_dropdown__single-value {
  cursor: pointer;
  font-size: 12px !important;
  color: #f07f1e !important;
}
 
.commentStyle {
  width: inherit;
  word-wrap: break-word;
  overflow: auto;
  text-align: left;
}
 
 
.addqst {
  max-width: 100%;
  background: transparent;
  border: transparent;
}
 
.datepic {
  position: relative;
}
 
span.cal_icon_date {
  position: absolute;
  right: 10px;
  top: 43px;
}
 
.qst-form {
  margin: 0 auto;
}

.ratings-wrapper{
  width: 15%;
}

.qst-form .form-group {
  text-align: left;
  position: relative;
}
 
.qst-form .form-group label {
  margin-bottom: 10px;
}
 
.question-type label {
  margin-right: 10px;
}
 
.question-type button.active-btn,
.question-type button:hover {
  /* border: 1px solid #f5821f !important;
  color: #f5821f !important;
  background-color: transparent !important; */
}
 
.addfield-content button.btn.btn-primary {
  width: 10%;
  margin-left: 10px;
  padding: 10px 10px;
  /* margin-top: -5px; */
}
 
.addfield-content input {
  width: 88%;
  margin-right: 2px;
  float: left;
}
 
.backto-manage {
  float: right;
  margin-top: 10px;
}
 
.addfield-content {
  width: 100% !important;
  margin-bottom: 10px;
}
 
.buClass {
  color: orange;
}
 
.custom-form.qst-form {
  display: flex;
  flex-direction: column;
}
 
input[type="radio"]:checked {
  background-color: #F5821F;
  border-color: #F5821F;
}
 
/**/
.custom-form.qst-form {
  margin-bottom: 20px;
  background-color: #fff;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, .05);
  padding: 40px 15px;
}
 
.custom-form.qst-form .selectpicker_dropdown {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  border-bottom: 0;
}
 
.btn-qst-type button {
  background-color: #fff !important;
  color: #f5821f;
  width: 105px;
  height: 39px;
  font-size: 16px !important;
  font-weight: 400;
  text-transform: capitalize !important;
}
 
.checkClass {
  margin-right: 15px;
}
 
.btn-qst-type {
  display: inline-block;
  background: #F2994A;
  padding: 5px 10px;
}
 
.add_btns {
  height: 41.9px;
  text-align: center;
  padding: 5px 15px;
  width: 7%;
  float: right;
  margin-right: 7px;
}
 
.btn-qst-type button:hover {
  background: #fff !important;
  color: #f5821f !important;
}
 
button.add_btns {
  /* position: absolute;
  bottom: 15px;
  right: 0; */
  margin-top: 10px;
  width: 100px;
  margin-right: 3px;
}
 
.add_delete,
.add-deletebtn {
  position: relative;
}
 
.qst-form .form-group .btn-qst-type label {
  margin-bottom: 0;
  font-size: 20px;
  position: relative;
  top: 5px;
}
 
.edit-qstafter .addfield-content {
  /* margin-left: -3px; */
}
 
.edit-qstafter .addfield-content button.btn.btn-primary {
  padding: 11.6px 10px;
}
 
.btncenter {
  margin-top: 15px
}
 
.addfield-content input:focus {
  border: 1px solid #ccc !important;
}
 
.custom-form .form-control {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}
 
.mandatory-check input[type="checkbox"]:focus {
  border: 2px solid #e74c3c !important;
}
 
.mandatory-radio input[type="radio"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 15px;
  height: 15px;
  border: 2px solid #F5821F;
  border-radius: 50%;
  background-color: white;
  position: relative;
  vertical-align: text-bottom;
}
 
.mandatory-radio label {
  margin-left: 5px;
}
 
.mandatory-radio input[type="radio"]:checked {
  background-color: #F5821F;
}
 
.mandatory-radio input[type="radio"]::before {
  content: "";
  width: 6px;
  height: 6px;
  background-color: #fff;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
 
 
.mandatory-check input[type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #e74c3c;
  background-color: white;
  position: relative;
  vertical-align: text-bottom;
  border-radius: 4px;
}
 
.mandatory-check label {
  margin-left: 5px;
}
 
.mandatory-check input[type="checkbox"]:checked {
  background-color: #e74c3c;
}
 
.mandatory-check input[type="checkbox"]::before {
  content: "\2713";
  font-size: 16px;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
 
.question-type button.active-btn {
  background-color: #f5821f !important;
  border-color: #f5821f !important;
  color: #ffffff !important;
}
 
.bgwhite.differ-login .style-deadline {
  display: inline-block;
  text-align: left;
  width: 100%;
}
 
.text-red {
  color: red;
}
 
.comment-error-rating {
  /* position: absolute; */
  right: 32px;
  top: 0;
  font-size: 12px;
  color: red;
}
 
.btn-primary.disabled {
  color: orange
}
 
button.btn.btn-primary.disable {
  background-color: #f5821f;
  border-color: #f5821f;
}
 
.t-t-corner {
  position: relative;
  left: 444px;
}
 
.w-30 {
  width: 30%;
}
 
.multiselect-scrollbar .select_dropdown__value-container.select_dropdown__value-container--is-multi.select_dropdown__value-container--has-value {
  height: 61px;
  overflow-y: scroll;
  width: 100%;
}
 
.star-wrapper textarea {
  padding: 5px;
  height: 55px;
  width: 720px;
  border: 1px solid #ddd;
}
 
.textarea-review{
  margin-left: 20px;
  margin-right: 20px;
}

.textarea-review textarea, .textarea-review textarea:focus{
  border: 1px solid #ccc;
  padding: 5px;
}

.pms-tabs .nav-tabs .nav-link.active, .pms-tabs .nav-tabs .nav-link:hover{
  background: transparent;
  border: 1px solid #e1e1e1 !important;
  color: orange;
}

.pms-tabs .nav-tabs .nav-link.disabled {
  color: #bababa;
}

.fade.delete-question-modal.modal.show .modal-dialog,
.fade.add-setting-popup.modal.show .modal-dialog,
.fade.setting-modal-popup.modal.show .modal-dialog {
  top: 40%;
  margin: 0 auto !important;
}
 
button.me-2.add-setting-btn.btn.btn-primary {
  margin-left: 853px;
  margin-top: -77px;
}
 
.form-check-input:checked {
  background-color: #F5821F;
  border-color: #F5821F;
}
 
.form-switch .form-check-input {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='orange'/%3e%3c/svg%3e");
}
 
.form-switch .form-check-input:focus {
  box-shadow: none;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='orange'/%3e%3c/svg%3e");
}
 
 
.addQuestionModal .addfield-content.afteredit input {
  margin-right: 0;
  width: 87%;
}
 
.addQuestionModal .addfield-content.afteredit button.btn.btn-primary {
  width: 10%;
}
 
.addQuestionModal {
  overflow: hidden;
}
 
.addQuestionModal .modal-content {
  max-height: 90vh;
  overflow-y: auto;
}
 
.addQuestionModal .modal-dialog {
  margin-top: 30px !important;
 
}
 
 
.modal-open {
  overflow-y: hidden;
}
 
.max-w13 {
  max-width: 14.4%;
}

.pms-date_picker .cal_icon_date {
  right: 26px;
  top: -5px;
}

.pms-date_picker input.datepicker-control-section {
  z-index: inherit;
}

.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL .sidenav---sidenav-subnav---1EN61 > .sidenav---sidenav-subnavitem---1cD47::before {
  position: relative !important;
}

.list-qst{
  border: 1px solid #ccc; 
  justify-content: space-between; 
  list-style: none; 
  display: flex; 
  background: #ffffff; 
  align-items: center; 
  border-radius: 10px; 
  padding: 15px; 
  margin-bottom: 10px; 
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); 
}
.drag-list{
  cursor: move;
}
.item.dragging { 
  opacity: 0.6; 
  border: 1px solid #ccc; 
  justify-content: space-between; 
  list-style: none; 
  display: flex; 
  cursor: move; 
  background: #ffffff; 
  align-items: center; 
  border-radius: 10px; 
  padding: 15px; 
  margin-bottom: 10px; 
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1); 
} 

@media (min-width: 576px) {
  .addQuestionModal .modal-dialog {
      max-width: 700px;
      margin: 1.75rem auto;
  }
}